.divBlock {
    /* height: 240px; */
}

.divBlock p ul li {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1;
}

.frmSearch {
    /* margin-top: -1.75rem; */
    z-index: 110000000;
}
.text-form {
    width: 80%;
}
.label-form {
    width: 20%;
}

.card:hover {
    transform: scale(1.01);
    cursor: pointer;
}
.title {
    border-left: 5px solid rgba(105, 172, 43, 1);
    height: 40px;
    align-items: center;
    display: flex;
}

.Block-curriculum img {
    max-height: 150px   ;
    object-fit: cover;
    background-repeat:no-repeat;
    background-position: center center;
}

.blockquote {
 height: 200px;
}

.blockquote p ul li {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1;
}

.dangerHTML{           
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-bottom: 0px;
}

figure {
    padding-bottom: 0px !important;
}

footer {
    position: absolute;
    /* bottom: 1rem; */
    /* margin-top: 10px; */
}

.tag {
    background-color: #93e4f4;
    color:#1285c1 !important;
    font-weight: bold;
    border-radius: 3px;
}


.text-editor{
    /* white-space: nowrap !important; */
    overflow: hidden !important;
    /* height: 40% !important; */
    width: 100%;
    height: 100px;
    text-overflow: ellipsis !important;
    
}

.backScreen-pb{
    padding-bottom: 14rem !important;
}
@media (min-width: 390px) {
    .text-editor{
        height: 70px;
    }
    
    .backScreen-pb{
        padding-bottom: 17rem !important;
    }
    
    .Block-curriculum img {
        max-height: 35vw;
    }
}

@media (min-width: 768px) {
    .text-editor{
        height: 80px;
    }
    .backScreen-pb{
        padding-bottom: 14rem !important;
    }
    .Block-curriculum img {
        max-height: 120px;
    }
}

@media (min-width: 1024px) {
    .text-editor{
        height: 80px;
    }
    .backScreen-pb{
        padding-bottom: 14rem !important;
    }
    .Block-curriculum img {
        max-height: 150px;
    }
}

.react-tags {
    position: relative;
    padding: 6px 6px 0 6px;
    border: 1px solid #D1D1D1;
    border-radius: 0.25rem;
    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;
    /* clicking anywhere will focus the input */
    cursor: text;
    background-color: #FFFFFF;
    width: 80%;
}

.react-tags__search-input {
    margin:0px;
    padding: 4px 4px;
    border: 2px solid #D1D1D1;
}

.react-tags__selected-tag {
    padding: 6px 8px;
}