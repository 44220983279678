.course-content {
    border-bottom: 1px solid #ccc;
}
.img-subject {
    object-fit: contain;
}
.title-att,
.attach-list {
    border-bottom: 1px solid #eeeeee;
}
.linkDownload {
    border-right: 1px solid #ccc;
    color: #0993ca;
}
.fa-file-pdf {
    color: red;
}
.fa-file-word {
    color: blue;
}
.fa-file-excel{
    color: green;
}


.ReactQuill-Editor{
    margin: auto;
  }

  .ReactQuill-Editor   .ql-snow *{
    box-shadow: none;
  }

  .ReactQuill-Editor   .ql-container.ql-snow {
    border:0px;
  }

  .ReactQuill-Editor .ql-editor{
    min-height: 0px !important;
  }

  
  .dangerHTML{           
    width:100%;
    height: 100%;
    /* padding:20px; */
  }

  @media print {
    .pagebreak {   
      clear: both;
      page-break-after: always;
    } /* page-break-after works, as well */
  }

  .comment{
    padding: 10px;
    margin: 10px 10px;
    /* color: #fff; */
  }
  .createComment{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    background-color: #eeee;
  }
  .commentAuthor {
    font-weight: bold;
    font-size: 1.2rem;    
  }

  .commentContent{
    font-size: 1.2rem;
  }
  /* h2{
    margin:0;
    margin-bottom: 5px;
  }
  h3{
    margin:0;
  } */
  /* input{
    font-family: 'Darker Grotesque', sans-serif;
    font-size: 18px;
    border-radius: 15px;
    border: 1px solid #787878;
    padding: 5px 5px 5px 10px;
    transition: 0.2s;
  }
  input:focus{
    outline: none;
    border: 1px solid #fc6f20;
  }
  input::placeholder{
    color: #bebebe;
  } */
  .textComment{
        margin-top: 10px;
        border: 1px solid #78787863;
        padding:20px;
        transition: 0.2s;
        box-sizing: border-box;
        resize: none;
  }
  .textComment:focus{
    outline: none;
    border: 1px solid #08aff3;
  }
  .textComment::placeholder{
    color: #bebebe;
  }
  .btn-comment{
    color: #fff;
    transition: 0.5s;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background: #08aff3;
    margin-top: 10px;
    font-weight: bold;
  }
  /* .btn-comment:hover{
    box-shadow: none;
    transform: translateY(5px);
    transition: all .1s linear;
  } */
  .btn-comment:focus{
    outline: none;
  }
  .commentPic{
    max-width: 90px;
    width: 100%;
    height: 100%;
    margin-right: 15px;
    border-radius: 10px;
  }
  .comment{
    display: flex;
  }
  .commentPic{
    max-width: 65px;
    max-height: 65px;
  }
  .commentHeader{
    display: flex;
    align-items: center;
  }

  /* .commentBody{
    max-width: 580px;
    overflow-wrap: break-word;
  } */

  .divComment{
    width: 80%;
    margin: auto;
  }

  .divCommentMobile{
    width: 100%;
    margin: auto;
  }

